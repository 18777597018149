.product-home{
    box-shadow: 0 0 4px #dedede79;
    margin: 1px;
    padding-bottom: 1rem;
    border-radius: 8px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.product-home .image{
    height: 350px !important;
}
.product-home .product-img{
    border-radius: 8px 8px 0 0;
    width: 100%;
    height: 100%
}
.product-home .title{
    font-size: 30px;
    margin-top: 40px;
    margin-bottom: 0;
    font-weight: 400;
    color: #000;
    text-decoration: none!important;
}
.product-home .stock{
    font-size: 20px;
    margin-bottom: 20px;
}
.product-home .price{
    font-size: 24px;
    color: var(--primary-color);
    margin: 0;
}
.en .product-home{
    direction: ltr;
}
.ar .product-home{
    direction: rtl;
}
.ar .product-home .title,
.ar .product-home .stock{
    text-align: right;
}
.en .product-home .title,
.en .product-home .stock{
    text-align: left;
}
.productBag-lab{
    display: block;
}
.productBag-mob{
    display: none;
}
.productBag-lab,
.productBag-mob button{
    background-color: var(--primary-color);
    border-radius: 50%;
    padding: 8px 11px;
}
@media only screen and (max-width: 500px) {
    .productBag-lab{
        display: none;
    }
    .productBag-mob{
        display: block;
    }
    .product-home .px-3{
        padding-left: .5rem !important;
        padding-right: .5rem !important;
    }
}