.header-home{
  /* padding: 0px 40px; */
}
.header-home .carousel-indicators {
  display: none;
}
.header-home .carousel-item {
  position: relative;
}

.header-home .carousel-item .details {
  position: absolute;
  z-index: 2;
  top: 45%;
  
  width: 40%;
  transform: translate(-7%, -50%);
}
.en .header-home .carousel-item .details {
  left: 10%;
  right: auto;
}
.ar .header-home .carousel-item .details {
  right: 10%;
  left: auto;
}
.header-home .carousel-item .details .title {
  color: #fff;
  line-height: 1.2;
  font-size: 40px;
}
.header-home .carousel-item .details .description {
  line-height: 1.3;
  margin-bottom: 1.5rem;
}
.header-home .carousel-item .details button {
  background-color: var(--primary-color);
}
.header-home .carousel-item img {
  height: calc(100vh - 81.8px);
}
@media only screen and (max-width: 500px) {
  /* .header-home {
    padding: 0px 20px;
  } */
  .header-home .carousel-item img {
    height: calc(50vh - 101.5px);
  }
  .header-home .details{
    width: 90%!important;
  }
  .header-home .details .tag{
    font-size: 14px!important;
    margin-bottom: 4px!important;
  }
  .header-home .details .description{
    display: none!important;
  }
  .header-home .details .title{
    font-size: 20px!important;
  }
}
