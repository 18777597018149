.footer{
    background-color: var(--bg-primary-color);
    color: #fff;
}
.whatsapp{
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: var(--bg-primary-color);
    border-radius: 50%;
    padding: 10px 11px;
    z-index: 99;
}
.footer-top .logo{
    width: 180px;
}
.footer-top a{
    color: #000;
}
@media only screen and (max-width: 500px) {
    .mt-5 {
        margin-top: 1rem !important;
    }
    .footer{
        text-align: center;
    }
    .col-12{
        margin-bottom: 2rem;
    }
    .col-12:last-of-type{
        margin-bottom: 0;
    }
    .ar .footer .qr,
    .en .footer .qr {
        text-align: center;
    }
    .qr .qr-img{
        width: 100px;
    }
    .footer .qr div {
        justify-content: center;
    }
    .footer-logo{
        margin-top: 4rem;
        text-align: center;
    }
    .footer-logo .logo{
        width: 100px;
    }
    .footer-top .col-12 .mb-4{
        margin-bottom: 1rem!important;
    }
    .footer-top .col-12{
        text-align: center;
    }
}