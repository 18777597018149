.shop-by-category{
  margin-top: 3rem;
}
.shop-by-category .categories {
  display: flex;
  justify-content: center;
  gap: 1rem;
}
.shop-by-category .cate{
  cursor: pointer;
  overflow: hidden;
  border-radius: 11px;
  box-shadow: 0 0 5px #dedede7a;
}
.shop-by-category .cate .desc{
  position: absolute;
  background: none;
  top: 50%;
  left: 50%;
  min-width: 60%;
  max-width: 70%;
  background-color: #0e3a5d78;
  color: #fff;
  transform: translate(-50%, -50%);
  padding: 2rem;
  border-radius: 5px;
  text-align: center;
  text-wrap: nowrap;
  overflow: hidden;
}

.shop-by-category .cate img{
  min-height: 400px !important;
  height: 400px !important;
  transform: scale(1);
}
.shop-by-category .cate:hover img{
  transform: scale(1.1);
  transition: all .5s ease-in-out;
}
.shop-by-category .cate:hover p{
  font-size: 40px;
  transition: all .5s ease-in-out;
}
.shop-by-category .cate:hover .desc{
  background-color: var(--primary-color);
  transition: .5s ease-in-out;
}
.shop-by-category .desc p{
  font-size: 30px;
  margin-bottom: 0;
  color: #ffffff;
}
.shop-by-category .desc a{
  background: none;
  font-size: 16px;
  text-decoration: underline;
}
  /* .shop-by-category img {
    max-width: 300px;
    height: 300px;
    border-radius: 10px;
  } */
  .shop-by-category a{
    padding: 8px 20px;
    background-color: var(--primary-color);
    color: #ffffff;
    border-radius: 5px;
    text-decoration: none;
    margin-top: 12px;
  }
  @media only screen and (max-width: 500px) {
    .shop-by-category {
      padding: 1rem;
      margin-top: 1rem;
    }
    .shop-by-category h4 {
      font-size: 22px;
    }
    .shop-by-category h4::before,
    .shop-by-category h4::after{
      content: '';
    }
    .shop-by-category .desc p {
      font-size: 16px;
    }
    .shop-by-category .cate .desc {
      padding: 1rem 6px;
    }
    .shop-by-category .aos-init{
      opacity: 1 !important;
      transform: none !important;
    }
    .shop-by-category .cate img {
      min-height: 160px !important;
      height: 160px !important;
    }
    .shop-by-category .cate:hover img{
      transform: scale(1);
    }
    .shop-by-category .cate:hover p{
      font-size: 16px;
    }
  }