.navbar a{
    color: #000;
    font-size: 18px;
    font-weight: 500;
    padding: 6px 20px;
    line-height: normal
} 
.nav-logo img{
    width: 80px;
    /* height: 100px; */
}
.navbar a:hover{
    color: #b6b6b6;
}
.navbar .navbar-nav{
    gap: 25px
}
.navbar a.active{
    color: var(--primary-color) !important;
} 
.navbar-collapse {
    justify-content: flex-end;
}
.navbar .cart-num{
    background-color: #b96464;
    color: #fff;
    position: absolute;
    border-radius: 50%;
    right: -12px;
    top: -12px;
    padding: 0 6px;
    font-size: 14px;
}
.nav-mobile-logo{
    display: none;
}
@media only screen and (max-width: 500px) {
    .navbar {
        padding: .75rem;
    }
    .navbar-collapse{
        position: absolute;
        background: var(--primary-color);
        top: 72px;
        left: 0;
        width: 100%!important;
        z-index: 99;
        padding: 1.5rem 0;
    }
    .navbar-collapse a {
        margin-bottom: 8px;
    }
    .navbar-nav .nav-link.active,
    .navbar-nav .nav-link{
        color: #fff!important;
    }
    .nav-logo{
        display: none;
    }
    .nav-mobile-logo{
        display: block;
        text-align: start!important;
        padding: 0!important;
    }
    .navbar .login-icon span,
    .navbar .lang-icon span,
    .navbar .cart-icon span{
        display: none;
    }
}