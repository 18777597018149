.contact{
  padding: 110px;
  margin-top: 9rem;
  background-color: #B0825A;
  color: #fff;
  border-radius: 25px;
}
.contact .send{
  background-color: var(--primary-color);
  color: #fff;
  border-radius: 8px;
  padding: 20px 90px;
}
.contact .contact-whats{
  color: var(--primary-color);
  background-color: #fff;
  border-radius: 8px;
}
.getInTouch{
  color: var(--primary-color)
}
.contact input::placeholder,
textarea::placeholder{
  color: #fff;
}
.contact input, textarea{
  background-color: initial;
  color: #fff;
}
/* .contact input:a, */
.contact input:active{
  background-color: initial;
}
@media only screen and (max-width: 500px) {
  .contact {
    padding: 1rem;
    margin-top: 4rem;
    width: 95%;
  }
  .contact .row div:nth-of-type(3){
    margin-top: 2rem;
  }
  .login-form, 
  .signup-form {
    padding: 1rem 0;
  }
  .contact .send{
    padding: 14px 40px;
    margin-bottom: 2rem;
  }
}