.rent-modal .modal-dialog{
    margin-top: 6rem!important;
}
.rent-modal .days-button{
    height: 48px;
    line-height: 1;
    font-size: 24px;
}
.rent-modal .total{
    color: var(--primary-color);
    display: flex;
    justify-content: space-between;
    font-size: 20px;
}
.rent-modal .actions button{
    width: 10rem;
}
.accept-modal .modal-body{
    overflow-x: hidden;
}
.accept-modal .btn:disabled{
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}
.accept-modal .modal-body{
    overflow: scroll;
    height: 77vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.accept-modal .rule label{
    font-size: 20px;
    font-weight: 600;
}

.accept-modal .modal-content{
    height: 90vh
}