.products{
  margin-top: 4rem;
  min-height: 90vh;
}

  @media only screen and (max-width: 500px) {
    .products {
      margin-top: 2.5rem;
    } 
    .products input{
      width: auto!important;
    }
  }