.cart{
    margin-top: 5rem;
    min-height: 85vh;
}
.cart table tr td{
    vertical-align: middle;
    color: #000;
    border-color: #eee;
    text-align: center;
}
.cart table tr th{
    background-color: #f0f0f0;
    padding: 12px 3px;
    text-align: center;
}
.is-install input[type='checkbox'] {
    accent-color: var(--primary-color);
    width: 16px;
}
.cart .prod-btn{
    background: none;
    border: 1px solid #dedede;
    color: #000;
    padding: 0px 9px;
}
.cart .trash{
    background: none;
    padding: 0;
    color: var(--bs-danger);
}
.cart .minus{
    padding: 0px 11px;
}
.cart .product-cart{
    margin-bottom: 1.5rem;
    margin-top: 1rem;
    border-bottom: 1px solid #dedede;
    padding-bottom: 1.5rem;
}
.cart .payment-details{
    background-color: #f0f0f0;
    color: #000;
}
.cart .continue{
    background-color: var(--primary-color);
    color: #fff;
    margin-top: 2rem;
    border-radius: 8px;
}
.cart .product-cart:last-child{
    margin-bottom: 0;
    border-bottom: none;
    padding-bottom: 1rem;
}
.cart .coupon-code {
    background: var(--primary-color);
    padding: 20px 15px;
    border-radius: 10px;
    margin-bottom: 20px;
}
.cart .coupon-code h5 {
    color: #fff;
    margin-bottom: 15px;
}
.cart .form-coupon .form-group {
    position: relative;
}
.cart .form-coupon .form-control {
    background: #fff;
    border: 0;
    border-radius: 35px;
    height: 50px;
    color: #505153;
}
.cart .form-coupon .btn-site {
    height: 40px;
    position: absolute;
    top: 5px;
    border-radius: 35px;
    background-color: var(--primary-color);
}
.cart .form-coupon .btn-danger {
    height: 40px;
    position: absolute;
    top: 5px;
    border-radius: 35px;
    color: #fff;
    background-color: var(--bs-btn-bg);
    padding: 10px 15px;
}
.ar .cart .form-coupon .btn-site,
.ar .cart .form-coupon .btn-danger {
    left: 4px;
}
.en .cart .form-coupon .btn-site,
.en .cart .form-coupon .btn-danger {
    right: 4px;
}
.table-responsive{
    display: block;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
}
@media only screen and (max-width: 500px) {
    .cart{
        margin-top: 2rem;
    }
    .cart .row div:first-of-type img{
        width: 50px;
        height: 50px;
    }
    .cart .row div:nth-of-type(1){
        gap: 8px;
        align-items: center;
        margin-bottom: 4px;
    }
    .cart .row div:nth-of-type(1) h4{
        font-size: 18px;
    }
    .cart .row div:nth-of-type(1) h5{
        font-size: 16px;
    }
    .cart .trash img{
        width: 20px!important;
        height: 20px!important;
    }
    .cart .mob-view-actions{
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
    .cart .mob-view-actions .three{
        margin-top: 0!important;
    }
    .cart .payment-details .card-body{
        width: 100%;
    }
    .cart .payment-details{
        width: 100%;
        align-items: start!important;
    }
    .cart .two .mx-2{
        margin: 0!important;
    }
    .cart .two{
        display: flex;
    }
}