.home-products {
  margin-top: 3rem !important;
}

@media only screen and (max-width: 500px) {
  .home-products {
    margin-top: 2.5rem !important;
  }
  .product-home .title{
    margin-top: 22px;
    font-weight: 500;
  }
  .product-home .title,
  .product-home .price{
    font-size: 18px;
  }
  .product-home .stock{
    font-size: 16px;
    margin-bottom: 0;
  }
  .product-home .image{
    height: 200px !important;
  }
  .product-home .product-img{
    height: 100%;
  }
  .swiper{
    padding: 0 15px!important;
  }
}