.categories{
  margin-top: 4rem;
  min-height: 85vh;
}

.categories .cate{
  cursor: pointer;
  overflow: hidden;
  box-shadow: 0 0 5px #dedede85;
}
.categories .cate .desc{
  position: absolute;
  background-color: #0e3a5d78;
  top: 50%;
  left: 50%;
  color: #ffffff;
  transform: translate(-50%, -50%);
  padding: 2rem 1.5rem;
  border-radius: 5px;
  text-align: center;
  /* text-wrap: nowrap; */
}
.categories .cate:hover .desc{
  background-color: var(--primary-color);
  transition: .5s ease-in-out;
}

.categories .cate img{
  min-height: 326px !important;
  height: 326px !important;
}
.categories .cate:hover img{
  transform: scale(1.1);
  transition: transform .5s ease-in-out;
}
.categories .desc p{
  font-size: 22px;
  line-height: 1.4;
  margin: 0;
}
  /* .categories img {
    max-width: 300px;
    height: 300px;
    border-radius: 10px;
  } */

  @media only screen and (max-width: 500px) {
    .categories {
      padding: 1rem;
      margin-top: 2.5rem;
    }
    .categories .cate .desc {
      padding: 1rem 8px;
    }
    .categories .desc p {
      font-size: 18px;
      line-height: 1.2;
    }
    .categories .desc a {
      font-size: 14px;
    }
    .paths h1 {
      font-size: 25px;
    }
    .categories input{
      width: auto!important;
    }
    .categories .cate img{
      min-height: 160px !important;
      height: 160px !important;
    }
  }